const config = {
  apiKey: "AIzaSyB2UCzjlB1qAMP7ngA2wwUn0meZUdrPRkA",
  authDomain: "veloxforce-dev.firebaseapp.com",
  projectId: "veloxforce-dev",
  storageBucket: "veloxforce-dev.appspot.com",
  messagingSenderId: "990879627484",
  appId: "1:990879627484:web:f6471a710442d03b8ddc19",
  
  partnermerchant: "EKZCNUN7LQP9L",
  paypalsecret: "EF3KS2Xb0b7yPPXZglIH7n40BVW8IZJRRTqLVBF3AoZ211NnmV2eegybs44jq3KLMwoGX7I0feJhmEkn",
  paypalbncode: "360payment_PSP_360payment",
  paypalclientid: "AZQ0_F_iFzPT3Yk5aBiZzdZ82RWrZS7tB49uVDSAJIKy9nbb84x9ZZDdF21PR965wPh0JnGI0B5LCo4K",
  paypalauthurl: "https://api.sandbox.paypal.com",
  paypalurl: "https://api-m.sandbox.paypal.com",
}

export function getFirebaseConfig() {
  if (!config || !config.apiKey) {
    throw new Error('No Firebase configuration object provided.' + '\n' +
    'Add your web app\'s configuration object to firebase-config.js')
  } else {
    return config
  }
}