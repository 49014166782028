import React, { } from 'react'
import { getFirebaseConfig } from '../lib'
import { initializeApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'

const app = initializeApp(getFirebaseConfig())

export default class DBuyFormCls extends React.Component {
  
  constructor(props) {
    super(props)
    // console.log('dbuy props', props.props.merchantId, JSON.stringify(props))  
    this.state = {
      init: true,
      txnToken: '',
      synchpartnerid: '',
      firstName: props.props.firstName,
      lastName: props.props.lastName,
      refId: props.props.refId,
      accountId: props.props.merchantId,
      city: props.props.city,
      zip: props.props.zip,
      state: props.props.state,
      address: props.props.address,
      amount: props.props.amount,
      isFinal: false,
      errorMsg: false,
      authCode: '',
      authToken: '',
    }
    // console.log('accountId', this.state.accountId)
    this.setState = this.setState.bind(this)
    this.showDBuy = this.showDBuy.bind(this)
    this.finalize = this.finalize.bind(this)
    this.handlePayment = this.handlePayment.bind(this)
    this.setState({ firstName: this.state.firstName })
    this.setState({ accountId: this.state.accountId })
  }

  callback = async (event) => {
    console.log('callback: ', event)
    if ((typeof event.data == 'string' || typeof event.data == 'object') 
      && (event.data == 'Close Model' || event.data == 'Return To Merchant Shipping' || event.data == 'Close' 
      || event.data.action == 'setPayCloseModal')) 
      { 
        const data = {
          authToken: this.state.authToken,
          amount:  this.state.amount,
          partnerId: this.state.synchpartnerid,
          accountId: this.state.accountId,
          refId: this.state.refId,
          lastName: this.state.lastName,
          firstName: this.state.firstName,
          txnToken: this.state.txnToken,
          address: this.state.address, 
          city: this.state.city, 
          state: this.state.state, 
          zip: this.state.zip,
        }
        // console.log('SYNCHRONY UNIFI MODAL CLOSED', this.state.synchpartnerid, this.state.amount, event, this.state.txnToken, this.state.accountId) 
        const functions = getFunctions(app)
        const finalizeSynchTxn = httpsCallable(functions, 'onCall-finalizeSynchTxn')
        let finalResp = await finalizeSynchTxn(data)
        console.log('finalResp', JSON.stringify(finalResp))
        
        //send data back to parent
        this.props.dBuyFinished(finalResp)

        this.setState({ errorMsg: finalResp.data.error })
        if (finalResp.data.authCode) {
          this.setState({ isFinal: true })
          this.setState({ authCode: finalResp.data.authCode })
        }
    }
  }

  async componentDidMount() {
    const functions = getFunctions(app)
    const getSynchTxnToken = await httpsCallable(functions, 'onCall-getSynchTxnToken')
    let tokenResp = await getSynchTxnToken()
    
    let authToken = tokenResp.data.authToken
    let txnToken = tokenResp.data.txnToken // 'PI2092063312510191802c2a09'
    let synchpartnerid = tokenResp.data.synchpartnerid // 'PI20920633'
    let accountId = this.state.accountId
    let firstName = this.state.firstName
    let lastName = this.state.lastName
    // let refId = this.state.refId
    let address = this.state.address
    let city = this.state.city
    let state = this.state.state
    let zip = this.state.zip
    let amount = this.state.amount
    console.log(authToken, accountId, lastName, firstName)

    //generate unique transid - but cannot be more than 20 characters
    let txnId = crypto.randomUUID()
    txnId = txnId.substring(txnId.length-20)

    this.setState({ init: true })
    this.setState({ txnToken: txnToken })
    this.setState({ txnId: txnId })
    this.setState({ synchpartnerid: synchpartnerid })
    this.setState({ address: address })
    this.setState({ city: city })
    this.setState({ state: state })
    this.setState({ zip: zip })
    this.setState({ amount: amount })
    this.setState({ authToken: authToken })
    // console.log('componentDidMount', this.state.synchpartnerid, this.state.txnToken, txnId, refId)

    // Initialize the syfWidgetObject
    const syfWidgetObject = {}
    syfWidgetObject.syfPartnerId = synchpartnerid // 'PI20920633'
    syfWidgetObject.flowType = 'PDP'
    syfWidgetObject.childSyfMerchantNumber = '5348120502019268' // '5348120506313493'
    syfWidgetObject.promoOverRideFlag = "Y"
    // <div><label>Tran Codes: </label><input id="offerNumber1" name="offerNumber1" value="112,000" /></div>
    syfWidgetObject.offerNumber1 = "112,000"
    // console.log('syfWidgetObject', JSON.stringify(syfWidgetObject))
    // 5348120502019268 

    // Load the external script
    const script = document.createElement('script')
    script.id = 'syfMPPScript'
    script.type = 'text/javascript'
    script.src = 'https://spdpone.syfpos.com/mpp/UniFi.js'
    script.async = true
    document.body.appendChild(script)

    window.addEventListener('message', this.callback)

  }

  render() {
    
    const { 
     txnToken, 
     synchpartnerid,
     txnId,
     address,
     city,
     state,
     zip,
     amount,
     isFinal,
     authCode,
     errorMsg,
    } = this.state
    console.log('txnId', txnId)
    console.log('synchpartnerid', synchpartnerid)
    console.log('txnToken', txnToken)
    if (isFinal) {
      return (
      <div>Thank You!  Your Auth Code is {authCode} </div>
      )
    }
    if (errorMsg) {
      return (
      <div red>Error: {errorMsg} </div>
      )
    }
    /* if (init) {
      return (
          <div>
              <button type="button" id="checkouthtml" onClick={this.showDBuy}>
                  DBUY
              </button>
          </div>
      )
    }
    */ 
    // if (!init) {
      return (
          <div>
       
            <form name="dbuyform" id="dbuyform">
              
              <div>
                <label hidden htmlFor="transactionToken">Token to use:</label>
                <input id="transactionToken" type="hidden" name="transactionToken" defaultValue={txnToken}/>
              </div>
              
              <div>
                <label hidden htmlFor="syfPartnerId">Partner ID:</label>
                <input id="syfPartnerId" type="hidden" name="syfPartnerId" defaultValue={synchpartnerid} />
              </div>
              <div>
                <label hidden htmlFor="clientTransId">Client Transaction ID:</label>
                <input id="clientTransId" type="hidden" name="clientTransId" defaultValue={txnId}/>
              </div>
              <div>
                <label hidden htmlFor="processInd">Process Indicator:</label>
                <input id="processInd" type="hidden" name="processInd" defaultValue="3" />
              </div>
              <div>
                <label hidden htmlFor="custAddress1">Address1:</label>
                <input id="custAddress1" type="hidden" name="custAddress1" defaultValue={address} />
              </div>
              <div>
                <label hidden htmlFor="custAddress2">Address2:</label>
                <input id="custAddress2" type="hidden" name="custAddress2" />
              </div>
              <div>
                <label hidden htmlFor="custCity">City:</label>
                <input id="custCity" type="hidden" name="custCity" defaultValu={city} />
              </div>
              <div>
                <label hidden htmlFor="custState">State:</label>
                <input id="custState" type="hidden" name="custState" defaultValue={state} />
              </div>
              <div>
                <label hidden htmlFor="custZipCode">ZIP Code:</label>
                <input id="custZipCode" type="hidden" name="custZipCode" defaultValue={zip} />
              </div>
              <div>
                <label hidden htmlFor="transAmount1">Transaction Amount:</label>
                <input id="transAmount1" type="hidden" name="transAmount1" defaultValue={amount} />
              </div>
              <div>
                <label htmlFor="childSyfMerchantNumber">OR</label>
                <input
                  id="childSyfMerchantNumber"
                  name="childSyfMerchantNumber"
                  defaultValue="5348120502019268"
                />
              </div>
              
            </form>
      
            <button className="btnwide btn btn-primary mt-1 mb-1" type="button" id="checkouthtml" onClick={this.handlePayment}>
              Complete Payment With Synchrony Card
            </button>
          </div>
        )
      // }
  }
  
  async finalize() {
  
  }

  showDBuy() {
    console.log('showDBuy', this.state.init)
    this.setState({ init: false })
  }
  getJSON() {

  }
  handlePayment () {
    // Assuming syfMPP is defined in the external script
    if (window.syfMPP) {
      this.props.dBuyStarted()
      console.log('fields', document.getElementById('dbuyform'))
      window.syfMPP.calldBuyProcess(document.getElementById('dbuyform'), null)
      // window.syfMPP.calldBuyProcess(document.getElementById('dbuyform'), null)
    } else {
      console.error('syfMPP is not loaded')
    }
  }
}

// export default DBuyForm